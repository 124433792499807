<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";
import tr from "vue2-datepicker/locale/es/tr";

export default {
  components: { validationMessages },
  props: ['tenantSslData'],
  data() {
    return {
      tenant: '',
      name: '',
      url: '',
      tenants_options: [],
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    tenant: {
      required,
      maxLength: maxLength(16),
    },
    name: {
      required,
      maxLength: maxLength(64),
    },
    url: {
      required,
      maxLength: maxLength(256),
    },
  },

  watch: {
    tenantSslData(newVal) {
      this.tenant = newVal.tenant;
      this.name = newVal.name;
      this.url = newVal.url;
    }
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.submitted = false;
    },

    async getTenants() {
      this.showLoader = true;
      try {
        const response = await Monitor.getTenants();
        this.tenants_options = response.data.tenants.map(tenant => ({ value: tenant, text: tenant }));
      } catch (error) {
        console.error("Error fetching tenants:", error);
      } finally {
        this.showLoader = false;
      }
    },

    async updateTenantSSL() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = {
        id: this.tenantSslData.id,  // Include the ID here
        tenant: this.tenant,
        name: this.name,
        url: this.url,
      };

      console.log('FormData:', formData); // Log formData to ensure id is present
      console.log('Tenant SSL ID:', this.tenantSslData.id); // Log the ID to ensure it's present

      try {
        const response = await Monitor.updateTenantSSL(this.tenantSslData.id, formData);
        if (response.data.error) {
          this.failedmsg(response.data.error);
        } else {
          this.successmsg(response.data.message);
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.showLoader = false;
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.tenant = "";
      this.name = "";
      this.url = "";
      this.$v.$reset();
    },

    modalShown(){
      this.getTenants();
    }
  },
};
</script>

<template>
  <b-modal  @shown="modalShown" id="edit_tenant_ssl" size="l" v-model="showModal" @hidden="resetForm" title="Edit Tenant Setup" title-class="font-18">
    <form @submit.prevent="updateTenantSSL" v-if="!showLoader">
      <div class="row" v-if="tenantSslData">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group label="Tenant" label-for="formrow-tenant-input" class="mb-3">
                <b-form-select
                    v-model="tenant"
                    :options="tenants_options"
                    :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Tenant'"
                                    :validationName="$v.tenant"></validationMessages>
              </b-form-group>

              <b-form-group label="Name" label-for="formrow-name-input" class="mb-3">
                <b-form-input v-model.trim="name" :class="{ 'is-invalid': submitted && $v.name.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Name'"
                                    :validationName="$v.name"></validationMessages>
              </b-form-group>

              <b-form-group label="Url" label-for="formrow-url-input" class="mb-3">
                <b-form-input v-model.trim="url" :class="{ 'is-invalid': submitted && $v.url.$error }"></b-form-input>
                <validationMessages v-if="submitted" :fieldName="'Url'"
                                    :validationName="$v.url"></validationMessages>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>
    <div class="card-body d-flex align-items-center justify-content-center" v-else>
      <b-spinner large></b-spinner>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="updateTenantSSL" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>
        Submit
      </b-button>
    </template>
  </b-modal>
</template>
